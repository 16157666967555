import { Flex, Heading, Image, Button, Text, Menu,
  MenuButton,
  MenuList, IconButton, Box } from '@chakra-ui/react';
import { FaBars } from 'react-icons/fa';
import React, {useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { ConnectButton } from '@rainbow-me/rainbowkit';



const Navbar = () => {

  

    return (
        <Flex justify="space-between" align="center" mb="30px" py="25px" position="contain" top="0" zIndex="contain" >
       

        <Flex gap="20px" align="center" wrap="wrap" display={{base: 'none', lg: 'flex'}}>

        <Link to="/">
              <Text fontSize="15px" color="white" _hover={{bg: 'primary.500'}} px="15px">
             Home
              </Text>
              </Link>



<Link to="/BUSDStake">

              <Text fontSize="15px" color="white" _hover={{bg: 'primary.500'}} px="15px">
              BUSD Stake
              </Text>
              </Link>

                

              
        </Flex>

      <Box display={{base: 'none', lg: 'block'}}> 
              <ConnectButton />
      </Box>

        <Box display={{base: 'block', lg: 'none'}}>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label='Options'
              icon={<FaBars />}
              colorScheme="black"
              font-family='hacked-kerX'
            />
            <MenuList bg="transparent" >

                <ConnectButton />

                <Link to="/">
              <Text fontSize="15px" color="white" _hover={{bg: 'primary.500'}} px="15px">
     Home
              </Text>
            </Link>
            <Link to="/BUSDStake" >
              <Text fontSize="15px" color="white" _hover={{bg: 'primary.500'}} px="15px">
              Busd Stake
              </Text>
            </Link>
           
             

               

                


                

                
            </MenuList>
          </Menu>
        </Box>
    </Flex>
    );
};

export default Navbar;