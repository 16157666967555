import React, {useState, useEffect} from 'react';

import { ethers } from 'ethers';
import { useAccount } from 'wagmi';

import ShieldStaking from '../artifacts/contracts/ShieldStaking.sol/ShieldStaking.json';
import ShieldToken from '../artifacts/contracts/ShieldToken.sol/ShieldToken.json';

import Logo from '../Images/shieldText.png';
import { Box, Heading, Button, Card, CardBody, Image, CardHeader, Flex, Grid, Text } from '@chakra-ui/react';
import '../Styles/Home.css'


import Swal from 'sweetalert2'





const Toast = Swal.mixin({
  toast: false,
  position: 'center center',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  
 
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
const swalOptions = {
  icon: 'info',
  title: 'Transaction Pending',
  text: 'Please wait while the transaction is being processed...',
  allowOutsideClick: true,
  showCancelButton: false,
  showConfirmButton: true,
  timer: 5000, // Set timer for 5 seconds (5000 milliseconds)
  onBeforeOpen: () => {
    Swal.showLoading();
  }
};
const successSwalOptions = {
  icon: 'success',
  title: 'Transaction Successful',
  text: 'Transaction Successful!',

  allowOutsideClick: true,
  showCancelButton: false,
  showConfirmButton: true
};





const shieldToken = '0x72EF3FD7271e5F83386EAf45FFc0C1AeCa0E8859';
const shieldStaking = '0x8de18bB6f1008D5A9F9e5F157556490ef842495D';


const Staking = () => {
    
       

    const { address, isConnecting, isDisconnected} = useAccount();
   
   
    const [balance, setBalance] = useState(0);
    const [depositValueRuff, setDepositValueRuff] = useState();
    const [claimable, setClaimableEth] = useState(0);
    const [unlockTime, setUnlockTime] = useState(0);
    const [shieldsTotal, setShieldsTotal] = useState(0);
    const [totalClaimed, setTotalCLaimed] = useState(0);
    const [stakedAmount, setStakedAmount] = useState(0);
    const [NFTbalance, setNFTBalance] = useState(0);
   
  






 


    useEffect(() => {
      // Your data fetching functions here
      
    
      fetchUserData();
      viewUserBalance();
      viewClaimableEth();
    
    
      // Listen for chain changes
    
      // Call the updateData function on page load
      updateData();
    }, []);


    
    const updateData = async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
   
      fetchUserData();
      viewUserBalance();
      viewClaimableEth();
    
    
      
    }


    
    
    
    const handleDepositChangeRuff = (e) => {
      setDepositValueRuff(e.target.value)
    }
    
//_________________________CALLABLE FUNCTIONS____________________________________________________
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------

async function ClaimEth() {
      
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(shieldStaking, ShieldStaking.abi, signer);
   
    
      let transaction = await contract.claimEth();
      await transaction.wait(Toast.fire(swalOptions));
    
     
    
 
      await transaction.wait();
      
      console.log('successful');
  
      const successSwalResult = await Swal.fire(successSwalOptions);
      const successSwalContainer = successSwalResult.isConfirmed ? successSwalResult.container : null;
      updateData();
      
    }
    catch (error) {
      console.error('Transaction failed:', error);
      Swal.fire({
        icon: 'error',
        title: 'Transaction Failed',
        text: error.reason,
        allowOutsideClick: true
      });
    }
     
  
}




  async function Stake15(depositValueRuff) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(shieldToken, ShieldToken.abi, signer);
    let approval = await contract.allowance(address,shieldStaking);
    let approvalamt = approval.toString()
    let depositValueFin = (depositValueRuff * 10**18);
    console.log("deposit value" + depositValueFin);
    console.log("approval Amount" + approvalamt )

    if(approvalamt <= depositValueFin){
       
      await approveTokens(depositValueRuff)
      }
    
    
  
  
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(shieldStaking, ShieldStaking.abi, signer);
      const TknAmt = ethers.utils.parseUnits(depositValueRuff.toString(), 'ether');
      const NewAmt = TknAmt;
      
        let transaction = await contract.stake(NewAmt, "1296000");
        await transaction.wait(Toast.fire(swalOptions));
      
       
      
   
        await transaction.wait();
        
        console.log('successful');
    
        const successSwalResult = await Swal.fire(successSwalOptions);
        const successSwalContainer = successSwalResult.isConfirmed ? successSwalResult.container : null;
        updateData();
       
      }
      catch (error) {
        console.error('Transaction failed:', error);
        Swal.fire({
          icon: 'error',
          title: 'Transaction Failed',
          text: error.reason,
          allowOutsideClick: true
        });
      }
    }
     
    

    async function Stake30(depositValueRuff) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(shieldToken, ShieldToken.abi, signer);
      let approval = await contract.allowance(address,shieldStaking);
      let approvalamt = approval.toString()
      let depositValueFin = (depositValueRuff * 10**18);
      console.log("deposit value" + depositValueFin);
      console.log("approval Amount" + approvalamt )
      if(approvalamt <= depositValueFin){
       
      await approveTokens(depositValueRuff)
      }
    
    
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(shieldStaking, ShieldStaking.abi, signer);
        const TknAmt = ethers.utils.parseUnits(depositValueRuff.toString(), 'ether');
        const NewAmt = TknAmt;
        
          let transaction = await contract.stake(NewAmt,"2592000");
          await transaction.wait(Toast.fire(swalOptions));
        
         
        
     
          await transaction.wait();
          
          console.log('successful');
      
          const successSwalResult = await Swal.fire(successSwalOptions);
          const successSwalContainer = successSwalResult.isConfirmed ? successSwalResult.container : null;
          updateData();
         
        }
        catch (error) {
          console.error('Transaction failed:', error);
          Swal.fire({
            icon: 'error',
            title: 'Transaction Failed',
            text: error.reason,
            allowOutsideClick: true
          });
        }
      }
  
   
  








      async function approveTokens(depositValueRuff) {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const newContract = new ethers.Contract(shieldToken, ShieldToken.abi, signer);
          const newAmts = depositValueRuff * 1.05;
          const TknAmt = ethers.utils.parseUnits(newAmts.toString(), 'ether'); // Convert to string before parsing
          const NewAmt = TknAmt.toString();
      
          let transaction = await newContract.approve(shieldStaking, TknAmt);
          await transaction.wait();
          Toast.fire(swalOptions);
        } catch (error) {
          console.error('Transaction failed:', error);
          Swal.fire({
            icon: 'error',
            title: 'Transaction Failed',
            text: error.reason || error.message, // Use error.message if error.reason is not available
            allowOutsideClick: true
          });
        }
      }





    async function unstake(depositValueRuff) {
     
      
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(shieldStaking, ShieldStaking.abi, signer);
        const TknAmt = ethers.utils.parseUnits(depositValueRuff.toString(), 'ether');
        const NewAmt = TknAmt;
        
          let transaction = await contract.unStake(NewAmt);
          await transaction.wait(Toast.fire(swalOptions));
    
          
        
     
          await transaction.wait();
          
          console.log('successful');
      
          const successSwalResult = await Swal.fire(successSwalOptions);
          const successSwalContainer = successSwalResult.isConfirmed ? successSwalResult.container : null;
          updateData();
          
        }
        catch (error) {
          console.error('Transaction failed:', error);
          Swal.fire({
            icon: 'error',
            title: 'Transaction Failed',
            text: error.reason,
            allowOutsideClick: true
          });
        }
      
    }



  

//_________________________CALLABLE FUNCTIONS END____________________________________________________
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------











//_________________________VIEWABLE FUNCTIONS____________________________________________________
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------



async function viewUserBalance() {
  if(typeof window.ethereum !== 'undefined') {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(shieldToken, ShieldToken.abi, provider);
    try {
      if(address) {
        const rum = await contract.balanceOf(address);
        let rumCount = await(rum);
        let rumCalc  = rumCount / 10**18;
        let rumTotal = rumCalc.toFixed(3)
        let tunFin = rumTotal.toString();
          
        setBalance(tunFin)
           
      }}
      catch (err) {
        console.log(err.message);
      }
    }
}




    async function viewClaimableEth() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(shieldStaking, ShieldStaking.abi, provider);
        try {
          if(address) {
            const rum = await contract.viewClaimableEth(address);
            let rumCount = await(rum);
            let rumCalc  = rumCount / 10**18;
            let rumTotal = rumCalc.toFixed(4)
            let rumFin = rumTotal.toString();
              
            setClaimableEth(rumFin)
            setTimeout(viewClaimableEth, 5000 );
           
               
          }}
          catch (err) {
            console.log(err.message);
          }
        }
    }




    async function fetchUserData() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(shieldStaking, ShieldStaking.abi, provider);
        try {
          if(address) {
            const rum = await contract.stakerVaults(address);
            let rumCount = await(rum);
            let tokens = rumCount[0].toString() / 10**18;
            let Total = tokens

            if(Total == "0"){ 
              Total = "Not Staked"
              setStakedAmount("N/A")
              setUnlockTime("0");
              setShieldsTotal("N/A");
              setTotalCLaimed("N/A");
              }



                else{ 
                  const provider = new ethers.providers.Web3Provider(window.ethereum);
                  const contract = new ethers.Contract(shieldStaking, ShieldStaking.abi, provider);
                  const rum = await contract.stakerVaults(address);
                  let time = rum[5].toString();
                  let shields = rum[3].toString() + "%";
                  let totalClaimed = (rum[8] / 10**18).toFixed(4);
                  let newTotal = totalClaimed.toString();
                  let tokens = rumCount[0] / 10**18;
                  let Total = tokens.toString()
                  setUnlockTime(time);
                  setShieldsTotal(shields);
                  setTotalCLaimed(newTotal);
                  setStakedAmount(Total)
                  


                 
                
                   }
                   setTimeout(fetchUserData, 5000 );
              
                


              
           
          }}
          catch (err) {
            console.log(err.message);
          }
    }}
    



  

//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------

    
    

  

    return (
        <Box mb="100px">
            <Box >
              <center>
                <Image 
                    src={Logo}
                    alt=''
                    transform="scale(1)"
                    width='200px'
                    transition= "0.3s ease-in-out"
                    _hover={{transform: "scale(1.15)"}}
                    />


                    <br></br>


                    <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="1px">
                    <Card textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                    <CardHeader className='cardtxt'>
                          Rewards Information
                        </CardHeader>
             
                    <Grid className='InfoTop' templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}} gap="5px">
                    <Card className='InfoTop1' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                    <Text color="whiteAlpha.800" >Nexus Balance</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{balance} </Text>
                    </Card>
                    <Card className='InfoTop1' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                    <Text color="whiteAlpha.800" >Total Claimed PWR</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{totalClaimed} </Text>
                    </Card>
                    <Card className='InfoTop1' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                    <Text color="whiteAlpha.800" >Rewards Percentage</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{shieldsTotal} </Text>
                    </Card>
                  
                
                    </Grid>
                    <Grid className='InfoTop' templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}} gap="5px">
                    <Card className='InfoTop1' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                    <Text color="whiteAlpha.800" >Token Unlock TIme</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{unlockTime != 0 ? new Date(unlockTime * 1000).toString().slice(0, 21).replace('T', 'T') : 'N/A'}
                        </Text>
                    </Card>
               
                    <Card className='InfoTop1' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                    <Text color="whiteAlpha.800" >Claimable PWR</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{claimable} </Text>
                    </Card>
               
                    <Card className='InfoTop1' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                    <Text color="whiteAlpha.800" >Locked Balance</Text>
                    <Text className="responseTop" color="whiteAlpha.800">{stakedAmount} </Text>
                    </Card>

                    <Card className='InfoTop' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                    </Card>

                    <Card className='InfoTop2' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                    <Button className='btnsubmit' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD'  onClick={() => ClaimEth()}>
                     <font><b>Claim Rewards</b></font> </Button>
                    </Card>
                    </Grid>
                    <br></br>
                            
                    </Card>
                    <br></br>
                    </Grid>
                    </center>
                    <br></br>

                


                <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="1px">

                    <Card textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt'>
                          Rewards Pool
                          <Text color="whiteAlpha.800" >If Your Approval Is Lower Than Your Lock Amount, Please Expect 2 Transactions.</Text>
                        </CardHeader>
                        <CardBody>
                        
                    <Text color="whiteAlpha.800" >Please Enter Amount To Lock Or Unlock</Text>
                        <input type="text" className="form-control" onChange={handleDepositChangeRuff} value={depositValueRuff} placeholder="Token Amount"></input> 
                     
                     </CardBody>
                     
                     <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)'}} gap="1px">
                    

                     <Card className='InfoTop' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                      <br></br>
                    </Card>
                    <Button className='btnsubmit1' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => Stake15(depositValueRuff)}>
                  <font><b>Lock 15 Days</b></font>
                  </Button>
                  <Button className='btnsubmit1' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => Stake30(depositValueRuff)}>
                  <font><b>Lock 30 Days</b></font>
                  </Button>
                  <Card className='InfoTop' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                      <br></br>
                    </Card>
                    </Grid>  
                  


                    <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}} gap="1px">
                  <Card className='InfoTop' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                    </Card>

                  <Button className='btnsubmit1' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => unstake(depositValueRuff)}>
                  <font><b>Unlock</b></font>
                  </Button>

                  <Card className='InfoTop' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                    </Card>

                  </Grid>      
                  </Card>
                  </Grid>
             </Box>

        </Box>
    );
};

export default Staking;