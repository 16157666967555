import { Flex, Image } from '@chakra-ui/react';
import React from 'react';
import img from '../Images/load.png'

const Loader = () => {
    return (

        <div className="loader">
        <img src={img} alt="Loading..." id="loadingImage" />
    </div>
    );
};

export default Loader;