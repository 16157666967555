import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  extendTheme
} from '@chakra-ui/react';
import { BrowserRouter, Routes,  Route } from 'react-router-dom';

import 'Styles/Home.css';
import Navbar from 'Components/Navbar';
import Loader from './Loader';
import ETH from '../Components/ETH'
import BUSDStake from '../Components/BUSDStake'
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
  midnightTheme,
} from '@rainbow-me/rainbowkit';
import {
  getWalletConnectConnector,
} from '@rainbow-me/rainbowkit';
import {
  chain,

  configureChains,
  createClient,
  WagmiConfig,
} from 'wagmi';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';

const MaxxChain: Chain = {
  id: 10201,
  name: 'Maxx',
  network: 'Maxx Chain',
  iconUrl: 'https://i.ibb.co/ZW0tMs8/Maxx.png',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'PWR',
    symbol: 'PWR',
  },
  rpcUrls: {
    default: {
      http: [' https://mainrpc.maxxchain.org'],//wizzard/pom RPC
    },
    public: {
      http: [' https://mainrpc.maxxchain.org'],//wizzard/pom RPC
    },
  },
  blockExplorers: {
    default: { name: 'Bscscan', url: 'https://scan.maxxchain.org/' }
    
  },
  testnet: false,
};

const BSCChain: Chain = {
  id: 56,
  name: 'BSC',
  network: 'Binance Smart Chain',
  iconUrl: 'https://i.ibb.co/NYwTK6y/pngwing-com.png',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'BNB',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: {
      http: ['https://bsc-dataseed1.binance.org/'],//wizzard/pom RPC
    },
    public: {
      http: ['https://bsc-dataseed1.binance.org/'],//wizzard/pom RPC
    },
  },
  blockExplorers: {
    default: { name: 'Bscscan', url: 'https://bscscan.com/' }
    
  },
  testnet: false,
};






// Add more wallet connexion with rainbowkit

const { provider, chains } = configureChains(
  [MaxxChain, BSCChain],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: `https://mainrpc.maxxchain.org`,
      }),
    }),
  ]
);

const { connectors } = getDefaultWallets({
  appName: '',
  projectId: '',
  chains,
  additionalConnectors: [
    new getWalletConnectConnector({
      rpc: { 1: 'Rpc.Maxxchain.org' }, // RPC URL for BSC
     
      qrcode: true,
      pollingInterval: 15000,
    }),
  ],
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})
// Add more wallet connexion with rainbowkit

// Custom Chakra default theme 

const colors = {
  primary: {
    900: '#050405',
    800: '#3A72F3',
    700: '#97C0FA',
    600: '#F9B208',
    500: '#FB5BB9',
    400: '#EB2A64',
    300: '#16A8FB'
  },
  secondary: {
    900: '#01040D',
    800: '#575761',
    700: '#1C1326'
  }
  
}




const styles = {
  global: {
    'html, body, css': {
      
      color: 'secondary.800',
      lineHeight: 'tall',
      fontSize: '16px',
      overflow: 'auto',
      
      
      
      
      backgroundImage: 'url("https://i.ibb.co/hRtZ7q5/black-white-background-with-white-background-black-white-background.jpg")', // Add the background image URL here
      backgroundSize: 'cover',
      backgroundPosition: 'center',
  

      
      boxSizing: 'border-box'
    },
    a: {
      fontSize: "20px",
      textDecoration: "none"
    }
    
  },
}

const breakpoints = {
  sm: '385px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  '2xl': '1536px',
}




const theme = extendTheme({ styles, breakpoints})

// Custom Chakra default theme 

function App() {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 3000)
  }, [])
  


  


  return loader ? (
    <Loader/>
  ) : (
        <ChakraProvider theme={theme} >
            <WagmiConfig client={wagmiClient}>
              <RainbowKitProvider chains={chains} theme={midnightTheme({
                accentColor: 'rgba(97, 97, 97, 0.47)',
                accentColorForeground: 'rgba(125, 133, 140, 0.8)',
                borderRadius: 'medium',
                color:  '#13ffd8',
                Body: 'hacked-kerX',
                

              })}>
                <BrowserRouter>
                <Box px={{base: "8%", md:"12%", lg: "15%"}}>
                  <Navbar/>
                  <Routes>
                 
                    
                    <Route path="/" element={<ETH/>}/>
                    <Route path="/BUSDStake" element={<BUSDStake/>}/>
                  
                  </Routes>
                </Box>
                </BrowserRouter>
              </RainbowKitProvider>
            </WagmiConfig>
        </ChakraProvider>
  );
}



export default App;
